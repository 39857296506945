import { render, staticRenderFns } from "./AdminHomePage.vue?vue&type=template&id=20383171&scoped=true"
import script from "./AdminHomePage.vue?vue&type=script&lang=js"
export * from "./AdminHomePage.vue?vue&type=script&lang=js"
import style0 from "./AdminHomePage.vue?vue&type=style&index=0&id=20383171&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20383171",
  null
  
)

export default component.exports