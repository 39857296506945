export const msalConfig = {
    auth: {
        clientId: '05963aec-f7aa-49c9-a5f8-2bae40ce3f2f', // This is the ONLY mandatory field that you need to supply.
        authority: 'https://login.microsoftonline.com/939e8966-9285-4a9a-9f04-0887efe8aae0', // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: window.location.origin, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: `${window.location.origin}/logout`, // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
}

export const apiScopes = ["api://93c00c2c-94e2-47a0-88ef-83f8ffc11dc7/user_impersonation"]
export const msGraphScopes = ["User.Read","Directory.Read.All"]

export const roles = {
    ADMIN: "VolunteerAdmin"
}