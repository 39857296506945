import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { msalConfig, apiScopes, roles } from './authConfig'
import { MsalPlugin } from './plugins/MSALPlugin'
import { ValidationProvider, ValidationObserver, extend, configure } from 'vee-validate'
import { required, numeric } from 'vee-validate/dist/rules'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/custom.css'

Vue.config.productionTip = false;

Vue.prototype.$isWeekofVolunteering = true;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

const msalOptions = {
  msalConfig: msalConfig,
  apiScopes: apiScopes,
  roles: roles
};
Vue.use(new MsalPlugin(), msalOptions);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

configure({
  classes: {
    invalid: 'is-invalid',
  }
});

extend('required', {
  ...required,
  message: 'This field is required'
});

extend('numeric', {
  ...numeric,
  message: 'This field should be numeric'
});

extend('atLeastOne', {
  param: "values",
  validate: (_value, values) => values.includes('1') || values.includes(1),
  message: "Check at least one {_field_}"
});

new Vue({
  render: h => h(App),
  router
}).$mount('#app')