<template>
  <div class="global-nav">

    <div class="all-sites-nav">
      <div class="top-menu-container">
        <ul class="top-menu">
          <li class="topNavLink"><router-link :to="{name: 'submitted-hours'}" class="first">My Volunteer Hours</router-link></li>
          <li class="topNavLink"><a href="#" @click="$msal.signOut()">Logout</a></li>
        </ul>
      </div>
    </div>

    <b-navbar toggleable="md" class="navbar-volunteer navbar-inner container-fluid">
      <b-navbar-brand to="/"><img class="nav-logo" src="../assets/img/gy-volunteer-form-logo.png" alt="Goodyear Logo"></b-navbar-brand>
      <b-navbar-toggle target="nav-collapse" >
        <span class="sr-only">Toggle navigation</span>
        <img src="../assets/img/gy_menu_mobile.png" alt="Menu Icon" width="35">
      </b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav >
        <b-navbar-nav class="ml-auto">
          <b-nav-item :to="{ name: 'submit-hours' }" link-classes="nav-link-custom">Submit Hours</b-nav-item>
          <b-nav-item-dropdown v-if="$msal.isAuthorized($msal.roles.ADMIN)" text="Admin" toggle-class="nav-link-custom" right>
            <b-dropdown-item to="/admin/" class="dropdown-link">Forms and Totals</b-dropdown-item>
            <b-dropdown-item v-on:click="exportServiceLogsToXLSX" class="dropdown-link">Export To Excel</b-dropdown-item>
            <b-dropdown-item v-on:click="exportTotalsToXLSX" class="dropdown-link">Export Totals</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

  </div>
</template>

<script>
const adminService = require("../services/admin.service");

export default {
  data() {
    return {};
  },
  methods: {
    async exportServiceLogsToXLSX() {
      try {
        // export forms to xls and send to email of current logged in user
        let response = await adminService.exportServiceLogs(this.$msal.getUserName());
        if (response.data && response.data.code == 0) {
          this.$bvModal.msgBoxOk("Report will be sent to your email address.", {centered: true});
        } else {
          this.$bvModal.msgBoxOk("An error has occured. Please try again.", {centered: true});
        }
      } catch(error) {
        console.log(error);
        this.$bvModal.msgBoxOk("An error has occured. Please try again.", {centered: true});
      }
    },
    async exportTotalsToXLSX() {
      try {
        // export totals to xls and send to email of current logged in user
        let response = await adminService.exportTotals(this.$msal.getUserName());
        if (response.data && response.data.code == 0) {
          this.$bvModal.msgBoxOk("Report will be sent to your email address.", {centered: true});
        } else {
          this.$bvModal.msgBoxOk("An error has occured. Please try again.", {centered: true});
        }
      } catch(error) {
        console.log(error);
        this.$bvModal.msgBoxOk("An error has occured. Please try again.", {centered: true});
      }
    }
  }
};
</script>

<style scoped>
  ::v-deep .nav-link-custom {
    color: #FEDA00 !important;
  }

  ::v-deep .dropdown-link a {
    color: #020202 !important;
  }

</style>