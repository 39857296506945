import * as msal from "@azure/msal-browser";
import { InteractionRequiredAuthError } from "@azure/msal-common";

let msalInstance;

export let msalPluginInstance;

export class MsalPlugin {
    #msalOptions = {};
    roles = [];

    install(vue, options) {
        if (!options) {
            throw new Error("MsalPluginOptions must be specified");
        }
        this.#msalOptions = options;
        this.initialize();
        msalPluginInstance = this;
        vue.prototype.$msal = msalPluginInstance;
    }

    async initialize() {
        this.roles = this.#msalOptions.roles;
        const msalConfig = this.#msalOptions.msalConfig;
        msalConfig.system = {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case msal.LogLevel.Error:
                            console.error(message);
                            return;
                        case msal.LogLevel.Info:
                            console.info(message);
                            return;
                        case msal.LogLevel.Verbose:
                            console.debug(message);
                            return;
                        case msal.LogLevel.Warning:
                            console.warn(message);
                            return;
                    }
                },
                piiLoggingEnabled: false,
                logLevel: msal.LogLevel.Verbose
            }
        };
        msalInstance = new msal.PublicClientApplication(msalConfig);
    }

    async signIn() {
        const loginRequest = {
            scopes: this.#msalOptions.apiScopes
        };
        await msalInstance.handleRedirectPromise();
        //redirect promise fulfilled only after redirect
        //and getAllAccounts returns an Object aslo after redirect
        //so we need to get into signIn function twice: on login and after redirect
        if(!this.isAuthenticated()) {
            // No user signed in
            msalInstance.loginRedirect(loginRequest);
        }
    }

    async signOut() {
        await msalInstance.logout();
    }

    async acquireToken(scopes, callApi) {
        const accessTokenRequest = {
            account: msalInstance.getAllAccounts()[0],
            scopes: scopes
        };

        try {
            let accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);
            return await callApi(accessTokenResponse.accessToken);
        } catch(error) {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            if (error instanceof InteractionRequiredAuthError) {
                msalInstance.acquireTokenRedirect(accessTokenRequest);
            }
        }
    }

    async getToken(scopes) {
        const accessTokenRequest = {
            account: msalInstance.getAllAccounts()[0],
            scopes: scopes
        };

        try {
            let accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);
            return accessTokenResponse.accessToken;
        } catch(error) {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            if (error instanceof InteractionRequiredAuthError) {
                msalInstance.acquireTokenRedirect(accessTokenRequest);
            }
        }
    }

    isAuthenticated() {
        const accounts = msalInstance.getAllAccounts();
        return accounts && accounts.length > 0;
    }

    isAuthorized(role) {
        let isAuthorized = false;
        const currentAccount = msalInstance.getAllAccounts()[0];
        if (currentAccount && currentAccount.idTokenClaims['roles']) {
            isAuthorized = currentAccount.idTokenClaims['roles'].includes(role);
        }
        return isAuthorized;
    }

    getUserName() {
        let username = '';
        const currentAccount = msalInstance.getAllAccounts()[0];
        if (currentAccount) {
            username = currentAccount.username;
        }
        return username;
    }
}