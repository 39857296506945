import Vue from 'vue'
import VueRouter from 'vue-router'
import { msalPluginInstance } from "./plugins/MSALPlugin"
import HomePage from './views/HomePage.vue'
import ForbiddenPage from './views/ForbiddenPage.vue'
import LogoutPage from './views/LogoutPage.vue'
import SubmitHoursPage from './views/SubmitHoursPage.vue'
import SubmittedHoursPage from './views/SubmittedHoursPage.vue'
import AdminHomePage from './views/admin/AdminHomePage.vue'
import AdminServiceLog from './views/admin/AdminServiceLog.vue'
import ThankYouPage from './views/ThankYouPage.vue'

const routes = [
  { path: '/', name: 'home', component: HomePage },
  { path: '/forbidden', name: 'forbidden', component: ForbiddenPage },
  { path: '/logout', name: 'logout', component: LogoutPage },
  { path: '/submit-hours', name: 'submit-hours', component: SubmitHoursPage },
  { path: '/submitted-hours', name: 'submitted-hours', component: SubmittedHoursPage },
  { path: '/thank-you', name: 'thank-you', component: ThankYouPage },
  { 
    path: '/admin/', 
    name: 'admin-home', 
    component: AdminHomePage,
    meta: {
      isAdmin: true,
    }
  },
  { 
    path: '/admin/service-log/:id', 
    name: 'admin-service-log', 
    component: AdminServiceLog,
    props: true,
    meta: {
      isAdmin: true,
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: () => {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, _from, next) => {
  if (to.name == 'forbidden' || to.name == 'logout') {
    next()
  } else {
    await msalPluginInstance.signIn();
    if(msalPluginInstance.isAuthenticated()) {
      if(to.meta.isAdmin){
        if(msalPluginInstance.isAuthorized(msalPluginInstance.roles.ADMIN)){
          next();
        } else {
          next({name: 'forbidden'});
        }
      } else {
        next();
      }
    }
  }
})

Vue.use(VueRouter)

export default router