<template>
  <div>
    <BaseHeader v-if="$msal.isAuthenticated()"/>
    <router-view/>
    <BaseFooter v-if="$msal.isAuthenticated()"/>
  </div>
</template>

<script>

  import BaseHeader from './components/BaseHeader.vue'
  import BaseFooter from './components/BaseFooter.vue'

  export default {
    components: {
      BaseHeader,
      BaseFooter,
    },
    name: 'volunteer-app'
  }

</script>