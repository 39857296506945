<template>
  <div>
    <hr />

    <div v-if="isBusy" class="text-center my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong> Loading...</strong>
    </div>

    <div v-if="isDataLoaded">
      <b-form inline>
        <b-form-select
          :options="YEAR_OPTIONS"
          v-model="year"
          size="sm"
        ></b-form-select>
      </b-form>

      <hr />

      <div>
        <h4 class="section-heading">
          <strong>Total number of hours by organization:</strong>
        </h4>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Service organization</b-th>
                <b-th>Number of hours</b-th>
                <b-th>Year</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totalsByYear.serviceOrgByYears"
                v-bind:key="index"
              >
                <b-td>{{ item.organization }}</b-td>
                <b-td>{{ item.total }}</b-td>
                <b-td>{{ item.year }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Other Service organization</b-th>
                <b-th>Number of hours</b-th>
                <b-th>Year</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totalsByYear.serviceOrgOthByYears"
                v-bind:key="index"
              >
                <b-td>{{ item.organizationOther }}</b-td>
                <b-td>{{ item.total }}</b-td>
                <b-td>{{ item.year }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>
      </div>

      <hr />

      <div>
        <h4 class="section-heading">
          <strong>Total number of hours by volunteer type:</strong>
        </h4>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Volunteer type</b-th>
                <b-th>Number of hours</b-th>
                <b-th>Year</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totalsByYear.typeVolunteerByYears"
                v-bind:key="index"
              >
                <b-td>{{ item.volunteerServiceType }}</b-td>
                <b-td>{{ item.total }}</b-td>
                <b-td>{{ item.year }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>
      </div>

      <hr />

      <div>
        <h4 class="mb-4 section-heading">
          <strong>Rating of the 5 questions under overall experience:</strong>
        </h4>

        <p>
          It's important to me that Goodyear is committed to making a positive
          impact in the community, with associate volunteer efforts.
        </p>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Answer</b-th>
                <b-th>Number of answers</b-th>
                <b-th>Year</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totalsByYear.positiveImpactByYears"
                v-bind:key="index"
              >
                <b-td>{{ item.formAnswer }}</b-td>
                <b-td>{{ item.totalAnswers }}</b-td>
                <b-td>{{ item.year }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>

        <hr />

        <p>
          I am more likely to speak positively of Goodyear as an employer because
          we are active in the community, with associate volunteer efforts.
        </p>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Answer</b-th>
                <b-th>Number of answers</b-th>
                <b-th>Year</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totalsByYear.speakePositivelyByYears"
                v-bind:key="index"
              >
                <b-td>{{ item.formAnswer }}</b-td>
                <b-td>{{ item.totalAnswers }}</b-td>
                <b-td>{{ item.year }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>

        <hr />

        <p>
          I am more likely to remain a Goodyear associate because we are active in
          the community, with associate volunteer efforts.
        </p>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Answer</b-th>
                <b-th>Number of answers</b-th>
                <b-th>Year</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totalsByYear.remainAssociateByYears"
                v-bind:key="index"
              >
                <b-td>{{ item.formAnswer }}</b-td>
                <b-td>{{ item.totalAnswers }}</b-td>
                <b-td>{{ item.year }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>

        <hr />

        <p>
          I feel a stronger sense of loyalty to Goodyear after participating in
          company supported volunteer opportunities.
        </p>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Answer</b-th>
                <b-th>Number of answers</b-th>
                <b-th>Year</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totalsByYear.senseOfLoyaltyByYears"
                v-bind:key="index"
              >
                <b-td>{{ item.formAnswer }}</b-td>
                <b-td>{{ item.totalAnswers }}</b-td>
                <b-td>{{ item.year }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>

        <hr />

        <p>
          Goodyear providing associate volunteer opportunities makes me feel more
          engaged at work.
        </p>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Answer</b-th>
                <b-th>Number of answers</b-th>
                <b-th>Year</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totalsByYear.engagedAtWorkByYears"
                v-bind:key="index"
              >
                <b-td>{{ item.formAnswer }}</b-td>
                <b-td>{{ item.totalAnswers }}</b-td>
                <b-td>{{ item.year }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>
      </div>

      <hr />

      <div>
        <h4 class="mb-4 section-heading">
          <strong>Week of Volunteering</strong>
        </h4>

        <p>
          The Week of Volunteering demonstrates Goodyear's commitment to serving
          our communities.
        </p>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Answer</b-th>
                <b-th>Number of answers</b-th>
                <b-th>Year</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totalsByYear.servingCommunitiesByYears"
                v-bind:key="index"
              >
                <b-td>{{ item.formAnswer }}</b-td>
                <b-td>{{ item.totalAnswers }}</b-td>
                <b-td>{{ item.year }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>

        <hr />

        <p>
          The Week of Volunteering demonstrates Goodyear's emphasis on energizing
          the team.
        </p>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Answer</b-th>
                <b-th>Number of answers</b-th>
                <b-th>Year</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totalsByYear.energizingTeamByYears"
                v-bind:key="index"
              >
                <b-td>{{ item.formAnswer }}</b-td>
                <b-td>{{ item.totalAnswers }}</b-td>
                <b-td>{{ item.year }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>

        <hr />

        <p>
          Participating in the Week of Volunteer improved my impressions of
          Goodyear.
        </p>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Answer</b-th>
                <b-th>Number of answers</b-th>
                <b-th>Year</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totalsByYear.improvedImpressionsGybyYears"
                v-bind:key="index"
              >
                <b-td>{{ item.formAnswer }}</b-td>
                <b-td>{{ item.totalAnswers }}</b-td>
                <b-td>{{ item.year }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>

        <hr />

        <p>
          My Week of Volunteering activity made a difference in the community.
        </p>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Answer</b-th>
                <b-th>Number of answers</b-th>
                <b-th>Year</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totalsByYear.differenceCommunityByYears"
                v-bind:key="index"
              >
                <b-td>{{ item.formAnswer }}</b-td>
                <b-td>{{ item.totalAnswers }}</b-td>
                <b-td>{{ item.year }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>

        <hr />

        <p>
          I understand how the volunteer activity I participated in relates to
          Goodyear's corporate social responsibility platform, Goodyear Better
          Future.
        </p>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Answer</b-th>
                <b-th>Number of answers</b-th>
                <b-th>Year</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totalsByYear.corporateSocialRespByYears"
                v-bind:key="index"
              >
                <b-td>{{ item.formAnswer }}</b-td>
                <b-td>{{ item.totalAnswers }}</b-td>
                <b-td>{{ item.year }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>
      </div>

      <hr />

      <div>
        <h4 class="section-heading">
          <strong
            >Is this your first-time volunteering at a Goodyear provided volunteer
            opportunity</strong
          >
        </h4>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Answer</b-th>
                <b-th>Number of answers</b-th>
                <b-th>Year</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totalsByYear.firstTimeVolByYears"
                v-bind:key="index"
              >
                <b-td>{{ item.firstTimeVol }}</b-td>
                <b-td>{{ item.totalAnswers }}</b-td>
                <b-td>{{ item.year }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>
      </div>

      <hr />

      <div>
        <h4 class="section-heading">
          <strong
            >"Overall, how would you rate your volunteer experience"
            totals:</strong
          >
        </h4>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Volunteer type</b-th>
                <b-th>Number of hours</b-th>
                <b-th>Year</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totalsByYear.experienceRateByYears"
                v-bind:key="index"
              >
                <b-td>{{ item.overallExpirience }}</b-td>
                <b-td>{{ item.total }}</b-td>
                <b-td>{{ item.year }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>
      </div>

      <hr />

      <div>
        <h4 class="section-heading">
          <strong>Totals by Region:</strong>
        </h4>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Region</b-th>
                <b-th>Number of hours</b-th>
                <b-th>Year</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totalsByYear.regionByYears"
                v-bind:key="index"
              >
                <b-td>{{ item.region }}</b-td>
                <b-td>{{ item.total }}</b-td>
                <b-td>{{ item.year }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>
      </div>

      <hr />

      <div>
        <h4 class="section-heading">
          <strong>Totals by Location:</strong>
        </h4>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Location</b-th>
                <b-th>Number of hours</b-th>
                <b-th>Year</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totalsByYear.locationByYears"
                v-bind:key="index"
              >
                <b-td>{{ item.location }}</b-td>
                <b-td>{{ item.total }}</b-td>
                <b-td>{{ item.year }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>
      </div>

    </div>

    <hr />
    
  </div>
</template>

<script>
const adminService = require("../../services/admin.service");

export default {
  data() {
    return {
      totalsByYears: {
        serviceOrg: [],
        serviceOrgOth: [],
        byVolunteerServiceType: [],
        gyPositiveImpact: [],
        gySpeakPositively: [],
        gyRemainAssociate: [],
        gySenseOfLoyalty: [],
        gyWorkEngagement: [],
        servingCommunities: [],
        energizingTeam: [],
        improvedImpressionsGY: [],
        differenceCommunity: [],
        corporateSocialResp: [],
        firstTimeVolByYears: [],
        experienceRate: [],
        byRegion: [],
        byLocation: []
      },

      year: new Date().getFullYear(),
      isDataLoaded: false,
      isBusy: false,
    };
  },
  mounted: async function () {
    this.isBusy = true;
    try {
      // split data loading by totals type to avoid SWA request timeout (45 sec)
      let response = await adminService.getTotalsByYears("ORGANIZATIONS");
      if (response.status === 200) {
        Object.assign(this.totalsByYears, response.data.data);
      } else {
        throw new Error(response.data.message);
      }

      response = await adminService.getTotalsByYears("VOLUNTEER_TYPE");
      if (response.status === 200) {
        Object.assign(this.totalsByYears, response.data.data);
      } else {
        throw new Error(response.data.message);
      }

      response = await adminService.getTotalsByYears("QUESTIONS");
      if (response.status === 200) {
        Object.assign(this.totalsByYears, response.data.data);
      } else {
        throw new Error(response.data.message);
      }

      response = await adminService.getTotalsByYears("QUESTIONS_WEEK_OF_VOLUNTEERING");
      if (response.status === 200) {
        Object.assign(this.totalsByYears, response.data.data);
      } else {
        throw new Error(response.data.message);
      }

      response = await adminService.getTotalsByYears("OTHER");
      if (response.status === 200) {
        Object.assign(this.totalsByYears, response.data.data);
      } else {
        throw new Error(response.data.message);
      }

      this.isBusy = false;
      this .isDataLoaded = true;
    } catch(error){
      this.isBusy = false;
      console.log(error.message);
      this.$bvModal.msgBoxOk("An error has occured. Please try again.", {centered: true});
    }
  },
  methods: {},
  computed: {
    totalsByYear() {
      return {
        serviceOrgByYears: this.totalsByYears.serviceOrg.filter(
          (item) => item.year === this.year
        ),
        serviceOrgOthByYears: this.totalsByYears.serviceOrgOth.filter(
          (item) => item.year === this.year
        ),
        typeVolunteerByYears: this.totalsByYears.byVolunteerServiceType.filter(
          (item) => item.year === this.year
        ),
        positiveImpactByYears: this.totalsByYears.gyPositiveImpact.filter(
          (item) => item.year === this.year
        ),
        speakePositivelyByYears:
          this.totalsByYears.gySpeakPositively.filter(
            (item) => item.year === this.year
          ),
        remainAssociateByYears:
          this.totalsByYears.gyRemainAssociate.filter(
            (item) => item.year === this.year
          ),
        senseOfLoyaltyByYears: this.totalsByYears.gySenseOfLoyalty.filter(
          (item) => item.year === this.year
        ),
        engagedAtWorkByYears: this.totalsByYears.gyWorkEngagement.filter(
          (item) => item.year === this.year
        ),
        servingCommunitiesByYears:
          this.totalsByYears.servingCommunities.filter(
            (item) => item.year === this.year
          ),
        energizingTeamByYears: this.totalsByYears.energizingTeam.filter(
          (item) => item.year === this.year
        ),
        improvedImpressionsGybyYears:
          this.totalsByYears.improvedImpressionsGY.filter(
            (item) => item.year === this.year
          ),
        differenceCommunityByYears:
          this.totalsByYears.differenceCommunity.filter(
            (item) => item.year === this.year
          ),
        corporateSocialRespByYears:
          this.totalsByYears.corporateSocialResp.filter(
            (item) => item.year === this.year
          ),
        firstTimeVolByYears: this.totalsByYears.firstTimeVolByYears.filter(
          (item) => item.year === this.year
        ),
        experienceRateByYears: this.totalsByYears.experienceRate.filter(
          (item) => item.year === this.year
        ),
        regionByYears: this.totalsByYears.byRegion.filter(
          (item) => item.year === this.year
        ),
        locationByYears: this.totalsByYears.byLocation.filter(
          (item) => item.year === this.year
        ),
      };
    },
  },
  created() {
    let currentYear = new Date().getFullYear();
    this.YEAR_OPTIONS = [
      currentYear - 5,
      currentYear - 4,
      currentYear - 3,
      currentYear - 2,
      currentYear - 1,
      currentYear,
    ];
  },
};
</script>

<style scoped>
.section-heading {
  color: rgb(39, 75, 167);
}
</style>
