import axios from 'axios';
import { apiScopes, msGraphScopes } from '../authConfig'
import { msalPluginInstance } from "../plugins/MSALPlugin"
import router from '../router';

const callApi = async (config) => {
  try {
    return await axios(config);
  } catch (err) {
    console.log(err);
    if(err.response.status === 403){
      router.push({ name: 'forbidden'});
    } else {
      //do we need to handle error 401? 
    }
    //return data to avoid errors in console
    return err.response;
    //or just rethrow error?
    //throw err;
  }
}

// SWA API
const getTokenAndCallApi = async (config) => {
  return msalPluginInstance.acquireToken(
    apiScopes,
    async (access_token) => {
      //Azure SWA replaces 'authorization' header with its own. So we use custom one.
      config.headers = {
        'x-custom-authorization':  `Bearer ${access_token}`
      };
      return callApi(config);
    }
  );
};

// MS Graph API
const getMSGraphTokenAndCallApi = async (config) => {
  return msalPluginInstance.acquireToken(
    msGraphScopes,
    async (access_token) => {
      config.headers = {
        'Authorization':  `Bearer ${access_token}`
      };
      return callApi(config);
    }
  );
};

export default class DataService {

  
  async getUserData() {
    const fields = ['givenName', 'surname', 'mail', 'department', 'country', 'officeLocation', 'id'];
    return getMSGraphTokenAndCallApi({url: `https://graph.microsoft.com/v1.0/me`, method: 'GET', params: {$select: fields.join()}});
  }
  
  async getCatalogItemsByCategory(category, isActive = 1) {
    return axios.get(`/api/catalog-items/${category}`, {params: {isActive: isActive}});
  }

  async createServiceLogItem(data) {
    return getTokenAndCallApi({url: '/api/service-log-items', method: 'POST', data: JSON.stringify(data)});
  }

  async updateServiceLogItem(data) {
    return getTokenAndCallApi({url: `/api/service-log-items/${data.id}`, method: 'PUT', data: JSON.stringify(data)});
  }

  async getServiceLogItemList(userName, year) {
    return getTokenAndCallApi({url: `/api/service-log-items`, method: 'GET', params: { userName: userName, year: year }});
  }
/*
  async getServiceLogItem(serviceLogId, userName) {
    return getTokenAndCallApi({url: `/api/service-log-items/${serviceLogId}`, method: 'GET', params: { userName: userName }});
  }
*/
  async updateGoal(userName, goal) {
    return getTokenAndCallApi({url: `/api/goal-items`, method: 'PUT', data: { userName: userName, goal: goal }});
  }
  async exportServiceLogItemList() {
    return getTokenAndCallApi({url: '/api/service-log-items-export', method: 'GET', responseType: 'blob'});
  }

}