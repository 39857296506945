export default {
  userName: null,
  userData: {},
  volunteerType: null,
  dateOfService: "",
  numberOfHours: 0,
  organizationId: null,
  organization: null,
  organizationOther: null,
  associateOrganizationId: [],
  associateOrganizationOther: null,
  volunteerServiceType: null,
  volunteerEffort: {
    safe: 0,
    smart: 0,
    sustainable: 0,
    other: 0
  },
  activityDescription: null,
  gyPositiveImpact: null,
  gySpeakPositively: null,
  gyRemainAssociate: null,
  gySenseOfLoyalty: null,
  gyWorkEngagement: null,
  servingCommunities: null,
  energizingTeam: null,
  improvedImpressionsGY: null,
  differenceCommunity: null,
  corporateSocialResp: null,
  firstTimeVol: null,
  overallExpirience: null,
  additionalComments: null,
  greatStory: 0,
  isDeleted: 0
}