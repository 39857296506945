<template>
  <div>
    <AdminIndServiceLog v-if="(serviceLogData.volunteerType === 1 && serviceLogYear >= 2017) || serviceLogData.volunteerType === 3" :service-log-item="serviceLogData"/>
    <AdminLegacyServiceLog v-if="(serviceLogData.volunteerType === 1 && serviceLogYear < 2017) || serviceLogData.volunteerType === 2" :service-log-item="serviceLogData"/>
  </div>
</template>

<script>
import ServiceLogItem from '../../models/service-log-item.js';
const adminService = require("../../services/admin.service");
import AdminIndServiceLog from '../../components/admin/AdminIndServiceLog.vue';
import AdminLegacyServiceLog from '../../components/admin/AdminLegacyServiceLog.vue';

export default {
  props: ["id"],
  data() {
    return {
        serviceLogData: JSON.parse(JSON.stringify(ServiceLogItem)),

        isBusy: false,
        errorMessage: '',
    };
  },
  mounted: async function() {
    this.isBusy = true;
    let response = await adminService.getServiceLog(this.id, this.$route.query.user);
    if (response.status === 200) {
        this.serviceLogData = response.data.data;
        this.serviceLogData.dateOfService = new Date(response.data.data.dateOfService);
        this.isBusy = false;
    } else {
        this.errorMessage = response.data.message;
        this.isBusy = false;
    }
  },
  computed: {
    serviceLogYear() {
      return this.serviceLogData.dateOfService.getFullYear();
    },
  },
  components: {
      AdminIndServiceLog,
      AdminLegacyServiceLog,
  },
};
</script>

<style scoped>
</style>