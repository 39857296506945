<template>
  <div class="tabs-container">
    <b-tabs content-class="mt-3" lazy>
      <b-tab title="Individual">
        <AdminIndServiceLogList/>
      </b-tab>
      <b-tab title="Group">
        <AdminGroupServiceLogList/>
      </b-tab>
      <b-tab title="Non Goodyear">
        <AdminNonGYServiceLogList/>
      </b-tab>
      <b-tab title="Totals By Years">
        <AdminTotalsByYears/>
      </b-tab>
      <b-tab title="Totals">
        <AdminTotals/>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import AdminIndServiceLogList from '../../components/admin/AdminIndServiceLogList.vue';
import AdminGroupServiceLogList from '../../components/admin/AdminGroupServiceLogList.vue';
import AdminNonGYServiceLogList from '../../components/admin/AdminNonGYServiceLogList.vue';
import AdminTotalsByYears from '../../components/admin/AdminTotalsByYears.vue';
import AdminTotals from '../../components/admin/AdminTotals.vue';

export default {
  data() {
    return {};
  },
  components: {
    AdminIndServiceLogList,
    AdminGroupServiceLogList,
    AdminNonGYServiceLogList,
    AdminTotalsByYears,
    AdminTotals
  }
};
</script>

<style scoped>
  .tabs-container {
    width: 90%;
    margin: 20px auto;
  }

  ::v-deep .nav-tabs > li > a {
    background-color: #dbdfe4;
    padding: 10px 13px;
    margin: 0 1px;
    color: #337ab7;
  }
</style>