import axios from 'axios';
import { apiScopes, msGraphScopes } from '../authConfig'
import { msalPluginInstance } from "../plugins/MSALPlugin"
import router from '../router';

  export async function getIndividualForms() {
    try {
      return await getTokensAndCallApi({ url: `/api/adm/service-logs`, method: 'GET', params: { type: 'individual' } })
    } catch (error) {
      return error.response;
    }
  }

  export async function getGroupForms() {
    try {
      return await getTokensAndCallApi({ url: `/api/adm/service-logs`, method: 'GET', params: { type: 'group' } })
    } catch (error) {
      return error.response;
    }
  }

  export async function getNonGYForms() {
    try {
      return await getTokensAndCallApi({ url: `/api/adm/service-logs`, method: 'GET', params: { type: 'non-gy' } })
    } catch (error) {
      return error.response;
    }
  }

  export async function getTotalsByYears(totalsCategory) {
    try {
      return await getTokenAndCallApi({ url: `/api/adm/totals-by-years`, method: 'GET' , params: { totalsCategory: totalsCategory } })
    } catch (error) {
      return error.response;
    }
  }

  export async function getTotals() {
    try {
      return await getTokenAndCallApi({ url: `/api/adm/totals`, method: 'GET' })
    } catch (error) {
      return error.response;
    }
  }

  export async function getServiceLog(id, userName) {
    try {
      return getTokenAndCallApi({ url: `/api/adm/service-logs/${id}`, method: 'GET', params: { userName: userName } });
    } catch (error) {
      return error.response;
    }
  }

  export async function deleteServiceLog(id, userName) {
    try {
      return getTokenAndCallApi({ url: `/api/adm/service-logs/${id}`, method: 'DELETE', data: { userName: userName } });
    } catch (error) {
      return error.response;
    }
  }

  export async function exportServiceLogs(email) {
    try {
      return await getTokensAndCallApi({ url: `/api/adm/export/service-logs`, method: 'POST', data: {email: email} });
    } catch (error) {
      return error.response;
    }
  }

  export async function exportTotals(email) {
    try {
      return await getTokenAndCallApi({ url: `/api/adm/export/totals`, method: 'POST', data: {email: email} });
    } catch (error) {
      return error.response;
    }
  }


  const callApi = async (config) => {
    try {
      return await axios(config);
    } catch (err) {
      console.log(err);
      if (err.response.status === 403) {
        router.push({ name: 'forbidden' });
      } else {
        //do we need to handle error 401? 
      }
      //return data to avoid errors in console
      return err.response;
      //or just rethrow error?
      //throw err;
    }
  }

  // SWA API
  const getTokenAndCallApi = async (config) => {
    return msalPluginInstance.acquireToken(
      apiScopes,
      async (access_token) => {
        //Azure SWA replaces 'authorization' header with its own. So we use custom one.
        config.headers = {
          'x-custom-authorization': `Bearer ${access_token}`
        };
        return callApi(config);
      }
    );
  };

  // Get token and MS Graph API token and call api
  const getTokensAndCallApi = async (config) => {
    let token = await msalPluginInstance.getToken(apiScopes);
    let msGraphToken = await msalPluginInstance.getToken(msGraphScopes);

    config.headers = {
      'x-custom-authorization': `Bearer ${token}`, 
      'x-msgraph-custom-authorization': `Bearer ${msGraphToken}`
    };

    return callApi(config);
  };