<template>
  <div class="container section-main border-left border-right mt-2 pt-2 pl-4 pr-4">
    <h1 v-if="serviceLog.volunteerType === 2">Goodyear's Group Volunteer Log</h1>
    <h1 v-if="serviceLog.volunteerType === 1">Goodyear Individual Volunteer Service Log</h1>
    <hr />
      <form>
        <fieldset :disabled="true">
          <div class="row form-group">
            <div class="col-md-2 pt-2">
              <label><strong><span class="text-danger">*</span>Date of Service:</strong></label>
            </div>
            <div class="col-md-3">
              <b-form-datepicker v-model="serviceLog.dateOfService" class="form-control" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" disabled></b-form-datepicker>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5">
              <div class="form-group">
                <label><strong><span class="text-danger">*</span>Description of Service Activity</strong></label>
                <textarea class="form-control" v-model="serviceLog.activityDescription" rows="3"></textarea>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-8">
              <p><strong><span class="text-danger">*</span>Type of Volunteer Service:</strong></p>
              <div class="pl-5">
                <div class="mb-3">
                  <b-form-radio v-model="serviceLog.volunteerServiceType" name="volunteer-service-type" value="1">
                    General <small><em>Any effort providing direct service to the community.</em></small>
                  </b-form-radio>
                  <b-form-radio v-model="serviceLog.volunteerServiceType" name="volunteer-service-type" value="2">
                    Leadership <small><em>(any effort serving in a leadership role, such as board involvement or a committee chair)</em></small>
                  </b-form-radio>
                  <b-form-radio v-model="serviceLog.volunteerServiceType" name="volunteer-service-type" value="3">
                    Service <small><em>(any effort using professional skills in a volunteer capacity)</em></small>
                  </b-form-radio>
                  <b-form-radio v-model="serviceLog.volunteerServiceType" name="volunteer-service-type" value="4">
                    Other <small><em>(any other efforts providing a service to the community)</em></small>
                  </b-form-radio>
                  <b-form-radio v-model="serviceLog.volunteerServiceType" name="volunteer-service-type" value="5">
                    Skills-Based <small><em>Any effort serving in a leadership role, like board involvement, or using professional skills in a volunteer capacity.</em></small>
                  </b-form-radio>
                </div>
                <div class="col-md-6">
                  <textarea v-if="serviceLog.volunteerServiceType === 4" class="form-control" v-model="serviceLog.TYPE_VOLUNT_OTHER_COM" rows="2"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-12">
              <p><strong><span class="text-danger">*</span>Did your volunteer effort fall within any of the following areas which Goodyear 
                      supports in the community? Please select all that apply. (Category Supplementals and Examples)</strong></p>
              <div class="row">
                <div class="col-md-1">
                  <strong>Category:</strong>
                </div>
                <div class="col-md-11">
                  <b-form-checkbox
                    v-model="serviceLog.volunteerEffort.smart"
                    value="1" unchecked-value="0"
                    inline
                  > Smart
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="serviceLog.volunteerEffort.safe"
                    value="1" unchecked-value="0"
                    inline
                  > Safe
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="serviceLog.volunteerEffort.sustainable"
                    value="1" unchecked-value="0"
                    inline
                  > Sustainable
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="serviceLog.volunteerEffort.other"
                    value="1" unchecked-value="0"
                    inline
                  > Other
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-5 pt-2">
              <label>
                <strong><span class="text-danger">*</span>Number of Hours:</strong>
                <div class="small"><em>(Please provide the number of hours for all associates involved in the activity. i.e. 2 associates volunteered 4 hours each, so the total is 8 hours.)</em></div>
              </label>
            </div>
            <div class="col-md-2">
              <b-form-input v-model="serviceLog.numberOfHours"></b-form-input>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-5 pt-2">
              <label>
                <strong>Number of individuals impacted from service:</strong>
                <div class="small"><em>(Enter the number of people that benefited from, or were reached by the volunteer program.)</em></div>
              </label>
            </div>
            <div class="col-md-2">
              <b-form-input v-model="serviceLog.PEOPLE_IMPACTED"></b-form-input>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-5 pt-2">
              <label>
                <strong><span class="text-danger">*</span>Number of associates involved:</strong>
              </label>
            </div>
            <div class="col-md-2">
              <b-form-input v-model="serviceLog.ASSOCIATES_INVOLVED"></b-form-input>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-4 pt-2">
              <label>
                <strong><span class="text-danger">*</span>Service Organization:</strong>
                <div class="small"><em>(Enter the name of the organization with which you volunteered)</em></div>
              </label>
            </div>
            <div class="col-md-3">
              <b-form-input v-model="serviceLog.organization"></b-form-input>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-5 pt-2">
              <label>
                <strong>Financial support provided, by Goodyear, if any:</strong>
              </label>
            </div>
            <div class="col-md-2">
              <b-input-group prepend="$">
                <b-form-input v-model="serviceLog.FINANCIAL_SUPPORT"></b-form-input>
              </b-input-group>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-5 pt-2">
              <label>
                <strong>Financial support provided, by Goodyear associates, if any:</strong>
              </label>
            </div>
            <div class="col-md-2">
              <b-input-group prepend="$">
                <b-form-input v-model="serviceLog.FINANCIAL_SUPPORT_ASSOC"></b-form-input>
              </b-input-group>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-4 pt-2">
              <label>
                <strong>In-kind support provided: </strong>
                <small><em>(materials or products)</em></small>
              </label>
            </div>
            <div class="col-md-3">
              <b-form-input v-model="serviceLog.IN_KIND_SUPPORT"></b-form-input>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-5">
              <div class="form-group">
                <label><strong>Links to media coverage of the volunteer activity</strong><small><em>(if available)</em></small>:</label>
                <textarea class="form-control" v-model="serviceLog.MEDIA_COVERAGE" rows="3"></textarea>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p><strong>If you participated as part of a group in this volunteer service project Goodyear provided, please answer the questions below.</strong></p>
              <div class="row">
                <div class="form-group col-12">
                  <p><strong>Overall, how would you rate your volunteer experience?</strong></p>
                  <div class="form-check form-check-inline">
                    <input type="radio" class="form-check-input" name="overall-expirience" id="overall-expirience-6" v-model="serviceLog.overallExpirience" value="6">
                    <label class="form-check-label" for="overall-expirience-6">Excellent</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input type="radio" class="form-check-input" name="overall-expirience" id="overall-expirience-7" v-model="serviceLog.overallExpirience" value="7">
                    <label class="form-check-label" for="overall-expirience-7">Good</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input type="radio" class="form-check-input" name="overall-expirience" id="overall-expirience-8" v-model="serviceLog.overallExpirience" value="8">
                    <label class="form-check-label" for="overall-expirience-8">Fair</label>
                  </div>
                  <div class="form-check form-check-inline">
                      <input type="radio" class="form-check-input" name="overall-expirience" id="overall-expirience-9" v-model="serviceLog.overallExpirience" value="9">
                      <label class="form-check-label" for="overall-expirience-9">Poor</label>
                  </div>
                </div>
              </div>
              <table class="table table-responsive table-experience">
                <thead>
                  <tr>
                    <th></th>
                    <th>Disagree strongly</th>
                    <th>Disagree somewhat</th>
                    <th>Neither agree or disagree</th>
                    <th>Agree somewhat</th>
                    <th>Agree strongly</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>It's important to me that Goodyear is committed to making a positive impact in the community, with associate volunteer efforts.</td>
                    <td class="text-center" v-for="(item, index) in agreementLevels" v-bind:key="index">
                      <input class="form-check-input" type="radio" name="gy-positive-impact" :id="'gy-positive-impact-'+index" v-model="serviceLog.gyPositiveImpact" :value="item.value">
                    </td>
                  </tr>
                  <tr>
                    <td>I am more likely to speak positively of Goodyear as an employer because we are active in the community, with associate volunteer efforts.</td>
                    <td class="text-center" v-for="(item, index) in agreementLevels" v-bind:key="index">
                      <input class="form-check-input" type="radio" name="gy-speak-positively" :id="'gy-speak-positively-'+index" v-model="serviceLog.gySpeakPositively" :value="item.value">
                    </td>
                  </tr>
                  <tr>
                    <td>I am more likely to remain a Goodyear associate because we are active in the community, with associate volunteer efforts.</td>
                    <td class="text-center" v-for="(item, index) in agreementLevels" v-bind:key="index">
                      <input class="form-check-input" type="radio" name="gy-remain-associate" :id="'gy-remain-associate-'+index" v-model="serviceLog.gyRemainAssociate" :value="item.value">
                    </td>
                  </tr>
                  <tr>
                    <td>I feel a stronger sense of loyalty to Goodyear after participating in company supported volunteer opportunities.</td>
                    <td class="text-center" v-for="(item, index) in agreementLevels" v-bind:key="index">
                      <input class="form-check-input" type="radio" name="gy-sense-of-loyalty" :id="'gy-sense-of-loyalty-'+index" v-model="serviceLog.gySenseOfLoyalty" :value="item.value">
                    </td>
                  </tr>
                  <tr>
                    <td>Goodyear providing associate volunteer opportunities makes me feel more engaged at work.</td>
                    <td class="text-center" v-for="(item, index) in agreementLevels" v-bind:key="index">
                      <input class="form-check-input" type="radio" name="gy-work-engagement" :id="'gy-work-engagement-'+index" v-model="serviceLog.gyWorkEngagement" :value="item.value">
                    </td>
                  </tr>
                  <tr>
                    <td>I valued the opportunity to engage with my colleagues in a volunteer setting.</td>
                    <td class="text-center" v-for="(item, index) in agreementLevels" v-bind:key="index">
                      <input class="form-check-input" type="radio" name="serving-communities" :id="'serving-communities-'+index" v-model="serviceLog.ENGAGE_VOLUNTEER_SETTING" :value="item.value">
                    </td>
                  </tr>
                  <tr>
                    <td>I learned more about my colleagues' skill sets and personalities, which increases my understanding of how to work with them professionally.</td>
                    <td class="text-center" v-for="(item, index) in agreementLevels" v-bind:key="index">
                      <input class="form-check-input" type="radio" name="energizing-team" :id="'energizing-team-'+index" v-model="serviceLog.COLLEAGUES_SKILL" :value="item.value">
                    </td>
                  </tr>
                  <tr>
                    <td>I feel a stronger sense of teamwork with my colleagues after participating in this group activity</td>
                    <td class="text-center" v-for="(item, index) in agreementLevels" v-bind:key="index">
                      <input class="form-check-input" type="radio" name="improved-impressions-gy" :id="'improved-impressions-gy-'+index" v-model="serviceLog.SENSE_OF_TEAMWORK" :value="item.value">
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          
          <div class="row">
            <div class="form-group col-md-6">
              <label><strong>Please share any additional feedback or suggestions regarding your volunteer service experience:</strong></label>
              <textarea class="form-control" v-model="serviceLog.additionalComments" rows="3"></textarea>
            </div>
          </div>
        </fieldset>
      </form>
  </div>
</template>

<script>
  import DataService from '../../services/data.service';
  const dataService = new DataService();

  export default {
    props: ["serviceLogItem"],
    data() {
      return {
        isBusy: false,
        showValidationErrorMsg: false,
        errorMsg: '',
        agreementLevels: []
      }
    },
    async mounted() {
      this.isBusy = true;
      try {
        let agreementLevels = await dataService.getCatalogItemsByCategory("AGREEMENT_LEVEL");
        this.agreementLevels = agreementLevels.data.data;
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
        console.log(error);
      }
    },
    computed: {
      serviceLog() {
        console.log(this.serviceLogItem)
        return this.serviceLogItem;
      },
    }
  }
</script>