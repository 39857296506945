<template>
  <div>
    <div class="mb-2">
      <b-form inline>
        <label>Show</label>
        <b-form-select
          id="inline-form-custom-select-pref"
          class="mb-2 ml-2 mr-sm-2 mb-sm-0"
          :options="[10, 25, 50, 100]"
          v-model="perPage"
          size="sm"
        ></b-form-select>
        <label>entries</label>
      </b-form>
    </div>

    <b-table 
      id="group-service-logs-table" 
      :items="groupServiceLogList"
      :fields="fields"
      :busy="isBusy"
      :per-page="perPage"
      :current-page="currentPage"
      table-class="group-service-logs-table"
      outlined
      striped 
      hover
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong> Loading...</strong>
        </div>
      </template>

      <template #cell(actions)="row">
        <div>
          <b-icon icon="box-arrow-up-right" class="mr-2 cursor-pointer" variant="success" v-on:click="openServiceLog(row)"></b-icon>
          <b-icon icon="trash" class="cursor-pointer" variant="success" v-on:click="showDeleteModal(row)"></b-icon>
        </div>
      </template>

    </b-table>
    <b-row>
      <b-col>
        {{positionText}}
      </b-col>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="group-service-logs-table"
          align="right"
        ></b-pagination>
      </b-col>
    </b-row>

    <!--- Delete Modal --->
    <b-modal ref="delete-group-service-log" content-class="shadow" @hidden="resetModal">
      <h1>Delete form</h1>
      Are you sure you want to delete this form?
      <template #modal-footer>
        <b-button class="btn btn-yellow" @click="deleteServiceLog()">
          Delete
        </b-button>
      </template>
    </b-modal>
    <!---End Delete Modal--->
    
  </div>
</template>

<script>
import moment from 'moment';
const adminService = require("../../services/admin.service");

export default {
  data() {
    return {
        groupServiceLogList: [],

        serviceLogToDelete: null,

        fields: [
          {
            key: 'firstName',
            sortable: true
          },
          {
            key: 'lastName',
            sortable: true
          },
          {
            key: 'userName',
            label: 'Email',
            sortable: true
          },
          {
            key: 'region',
            label: 'SBU',
            sortable: true
          },
          {
            key: 'department',
            sortable: true
          },
          {
            key: 'dateOfService',
            sortable: true,
            formatter: (value) => {
              return moment(value).format('MM/DD/YYYY');
            }
          },
          {
            key: 'volunteerServiceType',
            label: 'Type of Service',
            sortable: true
          },
          {
            key: 'volunteerEffort',
            label: 'Category',
            sortable: true
          },
          {
            key: 'numberOfHours',
            label: '# Hours',
            sortable: true
          },
          {
            key: 'reached',
            label: '# Reached',
            sortable: true
          },
          {
            key: 'associates',
            label: '# Associates',
            sortable: true
          },
          {
            key: 'organization',
            label: 'Service Org',
            sortable: true
          },
          { 
            key: 'actions', 
            label: 'Action',
            tdClass: 'row-actions'
          }
        ],

        perPage: 10,
        currentPage: 1,

        isBusy: false,

        errorMessage: "",
        showErrorModal: false,
    };
  },
  mounted: async function() {
    await this.loadData();
  },
  methods: {
    loadData: async function(){
      this.isBusy = true;
      let response = await adminService.getGroupForms();
      if (response.status === 200) {
          this.groupServiceLogList = response.data.data;
          this.isBusy = false;
      } else {
          this.errorMessage = response.data.message;
          this.showErrorModal = true;
          this.isBusy = false;
      }
    },
    openServiceLog: function(row){
      let routeData = this.$router.resolve({path: `/admin/service-log/${row.item.id}?user=${row.item.userName}`});
      window.open(routeData.href, '_blank');
    },
    deleteServiceLog: async function(){
      let response = await adminService.deleteServiceLog(this.serviceLogToDelete.item.id, this.serviceLogToDelete.item.userName);
      if (response.status === 200) {
        this.$refs['delete-group-service-log'].hide();
          await this.loadData();
      } else {
          this.errorMessage = response.data.message;
          this.showErrorModal = true;
          this.isBusy = false;
      }
    },
    showDeleteModal(row) {
      this.serviceLogToDelete = row;
      this.$refs['delete-group-service-log'].show();
    },
    resetModal() {
      this.serviceLogToDelete = null;
    }
  },
  computed: {
    rows() {
      return this.groupServiceLogList.length
    },
    positionText: function () {
      let endIndex = this.currentPage * this.perPage,
        startIndex = ((this.currentPage - 1) * this.perPage) + 1;

      return "Showing " + startIndex + " to " + (endIndex > this.groupServiceLogList.length ? this.groupServiceLogList.length : endIndex) 
                  + " of " + this.groupServiceLogList.length + " entries";
    }
  }
};
</script>

<style scoped>
  ::v-deep .group-service-logs-table>thead>tr>th {
    background-color: rgb(255, 255, 255) !important;
    color:rgb(0, 0, 0) !important;
  }

  ::v-deep .group-service-logs-table {
    border: none !important;
    border-bottom: 1px solid #111 !important;
  }

  ::v-deep .row-actions {
    min-width: 70px !important;
  }

  ::v-deep .page-item.active .page-link {
    color: #337ab7;
    background-color: #dbdfe4;
    border-color: #337ab7;
  }
</style>
