<template>
  <div class="container section-main pt-4">
    <h1>My {{year}} Volunteer Hours</h1>
    <div class="row">
      <div class="col-12 col-md-8">
        <table class="table">
          <thead>
            <tr>
              <th>Date of Service</th>
              <th>Service Organization</th>
              <th>Number of Hours</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in serviceLogs" v-bind:key="item.id">
              <td>{{item.dateOfService | moment}}</td>
              <td>{{item.serviceOrganization}}</td>
              <td>{{item.numberOfHours}}</td>
              <td>
                <a v-b-modal.modify-item title="Edit this item" @click="getDataForEdit(item)" v-if="isEditable">
                  <b-icon-pencil-square variant="info"></b-icon-pencil-square>
                </a>
              </td>
              <td>
                <a v-b-modal.modify-item title="Copy as New" @click="getDataForCopy(item)" v-if="isEditable">
                  <b-icon-clipboard-plus variant="info"></b-icon-clipboard-plus>
                </a>
              </td>
            </tr>
            <tr class="total">
              <td><strong>Total Hours</strong></td>
              <td></td>
              <td><strong>{{this.totalHours}}</strong></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <!--- Modify Item Modal--->
        <b-modal id="modify-item" size="lg" content-class="shadow" @hidden="resetModal">
          <h1 v-if="selectedItem.id">Edit Entry Details</h1>
          <h1 v-else>Copy Existing Item</h1>
          <ValidationObserver ref="form" v-slot="{ invalid }">
            <form>

              <div class="row">
                <div class="col-md-6">
                  <ValidationProvider rules="required" v-slot="{ classes, errors }">
                    <div class="form-group">
                      <label><strong><span class="text-danger">*</span>Date of Service:</strong></label>
                      <b-form-datepicker v-model="selectedItem.dateOfService" class="form-control" :class="classes" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <ValidationProvider rules="required|numeric" v-slot="{ classes, errors }">
                    <div class="form-group">
                      <label><strong><span class="text-danger">*</span>Number of Hours</strong></label>
                      <input type="text" class="form-control" :class="classes" v-model="selectedItem.numberOfHours">
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <ValidationProvider rules="required" v-slot="{ classes, errors }">
                    <div class="form-group">
                      <label><strong><span class="text-danger">*</span>Name of the organization with which you volunteered</strong></label>
                      <select class="form-control" :class="classes" v-model="selectedItem.organizationId" v-if="selectedItem.volunteerType==1">
                        <option v-for="organization in organizations" v-bind:value="organization.id" v-bind:key="organization.id">
                          {{ organization.title }}
                        </option>
                        <option value="0">Other</option>
                      </select>
                      <input type="text" class="form-control" :class="classes" v-model="selectedItem.organization" v-else>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-md-6" v-if="selectedItem.volunteerType==1">
                  <ValidationProvider :rules="{required: selectedItem.organizationId=='0' }" v-slot="{ classes, errors }">
                    <div class="form-group">
                      <label><strong><span class="text-danger" v-if="selectedItem.organizationId=='0'">*</span>Organization with which you volunteered (If not in previous list)</strong></label>
                      <input type="text" class="form-control" :class="classes" v-model="selectedItem.organizationOther" :disabled="selectedItem.organizationId!='0'">
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-md-6" v-if="selectedItem.volunteerType==1">
                  <div class="form-group">
                    <label><strong>Please select ALL Goodyear ERG's that you are involved with:</strong></label>
                    <b-form-checkbox v-for="organization in associateOrganizations" v-bind:key="organization.id" :id="organization.id" :value="organization.id" v-model="selectedItem.associateOrganizationId">
                      {{ organization.title }}
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="col-md-6">
                  <p><strong><span class="text-danger">*</span>Type of Volunteer Service:</strong></p>
                  <div v-for="(item, index) in volunteerServiceTypes" v-bind:key="index">
                    <div class="form-check" v-if="index+1<volunteerServiceTypes.length">
                      <input class="form-check-input" type="radio" name="volunteer-service-type" :id="'volunteer-service-type-' + index" v-model="selectedItem.volunteerServiceType" :value=item.value>
                      <label class="form-check-label" :for="'volunteer-service-type-' + index">{{item.title}}</label>
                      <a class="btn-popover px-2" tabindex="0" v-b-popover.click.blur.top="item.description">
                        <b-icon-question-circle-fill variant="info"></b-icon-question-circle-fill>
                      </a>
                    </div>
                    <ValidationProvider rules="required" v-slot="{errors}" v-else>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="volunteer-service-type" :id="'volunteer-service-type-' + index" v-model="selectedItem.volunteerServiceType" :value=item.value>
                        <label class="form-check-label" :for="'volunteer-service-type-' + index">{{item.title}}</label>
                        <a class="btn-popover px-2" tabindex="0" v-b-popover.click.blur.top="item.description">
                          <b-icon-question-circle-fill variant="info"></b-icon-question-circle-fill>
                        </a>
                      </div>
                      <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-6">
                  <p><strong><span class="text-danger">*</span>Which of Goodyear's Community Engagement focus areas best aligns with your volunteer effort?</strong></p>
                  <div class="form-group">
                    <div>
                      <b-form-checkbox inline id="safe" value="1" unchecked-value="0" v-model="selectedItem.volunteerEffort.safe">
                        Safe
                      </b-form-checkbox>
                      <a class="btn-popover" tabindex="0" v-b-popover.click.blur.top="'Ex: Safe Mobility Project, Safe Way to School, Rainy Season Awareness, Race Collaboration'">
                        <b-icon-question-circle-fill variant="info"></b-icon-question-circle-fill>
                      </a>
                    </div>
                    <div>
                      <b-form-checkbox inline id="smart" value="1" unchecked-value="0" v-model="selectedItem.volunteerEffort.smart">
                        Smart
                      </b-form-checkbox>
                      <a class="btn-popover" tabindex="0" v-b-popover.click.blur.top="'Ex: STEM Career Day, Student Environmental Training, HOPE School Project'">
                        <b-icon-question-circle-fill variant="info"></b-icon-question-circle-fill>
                      </a>
                    </div>
                    <div>
                      <b-form-checkbox inline id="sustainable" value="1" unchecked-value="0" v-model="selectedItem.volunteerEffort.sustainable">
                        Sustainable
                      </b-form-checkbox>
                      <a class="btn-popover" tabindex="0" v-b-popover.click.blur.top="'Ex: &quot;Pay Attention to the Environment&quot; Campaign, Sustainability Award for Water Conservation, Environmental Good Governance Award, Safety & Environmental Drawing Contest'">
                        <b-icon-question-circle-fill variant="info"></b-icon-question-circle-fill>
                      </a>
                    </div>
                    <ValidationProvider name="volunteer effort" :rules="{ atLeastOne: [selectedItem.volunteerEffort.safe,selectedItem.volunteerEffort.smart,selectedItem.volunteerEffort.sustainable,selectedItem.volunteerEffort.other] }" v-slot="{errors}">
                      <div>
                        <b-form-checkbox inline id="other" value="1" unchecked-value="0" v-model="selectedItem.volunteerEffort.other">
                          Other
                        </b-form-checkbox>
                        <a class="btn-popover" tabindex="0" v-b-popover.click.blur.top="'Ex: United Way, Goodyear Half Marathon and 10K, Hand in Hand Program, Road & Tire Safety Campaign'">
                          <b-icon-question-circle-fill variant="info"></b-icon-question-circle-fill>
                        </a>
                      </div>
                      <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </form>
            <div class="text-center mt-5">
              <p v-if="invalid && showValidationErrorMsg"><strong><span class="text-danger">Please check all required fields</span></strong></p>
              <p v-else-if="errorMsg"><strong><span class="text-danger">{{errorMsg}}</span></strong></p>
            </div>
          </ValidationObserver>
          <template #modal-footer="{ hide }">
            <b-button class="btn btn-yellow" @click="createOrUpdateServiceLogItem(hide);">
              <span v-if="selectedItem.id">Update Entry</span>
              <span v-else>Submit</span>
            </b-button>
          </template>
        </b-modal>
        <!---End Modify Item Modal--->

        <h2>Previous Years</h2>
        <p v-for="selectedYear in years" v-bind:key="selectedYear">
          <span v-if="selectedYear == year">View {{year}} Hours</span>
          <span v-else><a href="#" @click="$event.preventDefault();loadData(selectedYear)">View {{selectedYear}} Hours</a></span>
        </p>
			</div>

      <div class="col-12 col-md-4">
        <div class="card">
          <div id="gauge"></div>
          <p class="text-center" v-if="!isLoading && goal - totalHours > 0">{{goal - totalHours}} hours left towards goal</p>
          <div v-else-if="!isLoading">
            <p class="text-center"><strong>Congratulations! You have met your goal!</strong></p>
            <p class="small text-center" v-if="isEditable"> Click below to increase your goal.</p>	
            <p class="text-center" v-if="isEditable"><a class="small" v-b-modal.changeGoalModal>Change my goal</a></p>
            <!--- Change Goal Modal--->
            <b-modal id="changeGoalModal" content-class="shadow">
              <div class="row text-center">
                <div class="col-12">
                  <h1>Change My Goal</h1>
                  <h3><strong>Existing Goal:</strong> {{goal}} hours</h3>
                </div>
                <div class="col-md-6 offset-md-3 my-4">
                  <label for="newGoal"><strong>New Goal</strong></label>
                  <input class="form-control" type="number" min="0" v-model.number="newGoal">
                </div>
              </div>
              <template #modal-footer="{ hide }">
                <b-button class="btn btn-yellow" @click="changeGoal(hide)">
                  Update Goal
                </b-button>
              </template>
            </b-modal>
            <!---End Change Goal Modal--->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  import JustGage from 'justgage';
  import ServiceLogItem from '../models/service-log-item.js';
  import DataService from '../services/data.service';
  const dataService = new DataService();

  export default {
    data() {
      return {
        year: new Date().getFullYear(),
        isEditable: false,
        serviceLogs: [],
        totalHours: 0,
        gauge: null,
        goal: 0,
        newGoal: 0,
        //list years from current year to 2016
        years: [...Array(new Date().getFullYear() - 2016 + 1).keys()].map(x => x + 2016).reverse(),
        organizations: [],
        associateOrganizations: [],
        volunteerServiceTypes: [],
        selectedItem: JSON.parse(JSON.stringify(ServiceLogItem)), //clone to avoid caching, doesn't work for complex types (e.g. Date)
        showValidationErrorMsg: false,
        errorMsg: '',
        isLoading: false
      }
    },

    async mounted() {
      this.gauge = new JustGage({
        id: "gauge",
        value: this.totalHours,
        min: 0,
        max: 25,
        counter: true,
        title: "",
        label: "total hours",
        //titleFontColor: "#1a263c",
        labelFontColor: "#333333",
        startAnimationTime: 2000,
        startAnimationType: "bounce",
        refreshAnimationTime: 1000,
        refreshAnimationType: "bounce",
        //shadowOpacity: .25,
        //shadowSize: 3,
        //shadowVerticalOffset: 12,
        relativeGaugeSize: true,
        titlePosition: "below",
        gaugeWidthScale: 0.7,
        gaugeColor: "#d4ea85",
        levelColors: [ /*"#d9e9fd", "#0066dd",*/ "#86d630"]
      });

      this.isLoading = true;
      this.organizations = (await dataService.getCatalogItemsByCategory("ORGANIZATION")).data.data;
      this.associateOrganizations = (await dataService.getCatalogItemsByCategory("ASSOCIATE_ORGANIZATION")).data.data;
      this.volunteerServiceTypes = (await dataService.getCatalogItemsByCategory("VOLUNTEER_SERVICE_TYPE")).data.data;
      this.isLoading = false;

      await this.loadData(this.year);
    },

    methods: {
      async changeGoal(hide) {
        try {
          let userName = this.$msal.getUserName();
          await dataService.updateGoal(userName, this.newGoal);
          this.gauge.refresh(this.totalHours, this.newGoal);
          this.goal = this.newGoal;
          //this.newGoal = 0;
        } catch (error) {
          console.log(error);
        } finally {
          hide();
        }
      },

      async loadData(year) {
        this.isLoading = true;
        this.year = year;
        let userName = this.$msal.getUserName();
        let serviceLogItems = (await dataService.getServiceLogItemList(userName, year)).data.data;
        this.serviceLogs = serviceLogItems.serviceLogs;
        this.goal = serviceLogItems.goal;
        this.totalHours = 0;
        this.serviceLogs.forEach(serviceLog => {
          serviceLog.serviceOrganization =
            serviceLog.organization || serviceLog.organizationOther ||
            this.getTitleById(this.organizations, serviceLog.organizationId)
          this.totalHours = this.totalHours + +serviceLog.numberOfHours;
          serviceLog.dateOfService = new Date(serviceLog.dateOfService);
        });
        this.isEditable = year == new Date().getFullYear();
        if (this.gauge) {
          this.gauge.refresh(this.totalHours, this.goal);
        }
        this.isLoading = false;
      },

      getTitleById(items, id){
        let title = '';
        let searchedItem = items.find(item => item.id==id);
        if (searchedItem) {
          title = searchedItem.title;
        }
        return title;
      },

      getDataForEdit(item){
        this.selectedItem = item;
      },

      getDataForCopy(item){
        this.selectedItem = JSON.parse(JSON.stringify(ServiceLogItem));
        this.selectedItem.volunteerType = item.volunteerType;
        this.selectedItem.organizationId = item.organizationId;
        this.selectedItem.organizationOther = item.organizationOther;
        this.selectedItem.associateOrganizationId = item.associateOrganizationId;
        this.selectedItem.volunteerServiceType = item.volunteerServiceType;
        this.selectedItem.volunteerEffort.safe = item.volunteerEffort.safe;
        this.selectedItem.volunteerEffort.smart = item.volunteerEffort.smart;
        this.selectedItem.volunteerEffort.sustainable = item.volunteerEffort.sustainable;
        this.selectedItem.volunteerEffort.other = item.volunteerEffort.other;
        this.selectedItem.userName = item.userName;
        this.selectedItem.userData.email = item.userData.email;
        this.selectedItem.userData.department = item.userData.department;
        this.selectedItem.userData.country = item.userData.country;
        this.selectedItem.userData.officeLocation = item.userData.officeLocation;
        this.selectedItem.userData.firstName = item.userData.firstName;
        this.selectedItem.userData.lastName = item.userData.lastName;
        this.selectedItem.userData.msGraphId = item.userData.msGraphId;
      },

      createOrUpdateServiceLogItem: async function (hide) {
        let isFormValid = await this.$refs.form.validate();
        if(!isFormValid){
          this.showValidationErrorMsg = true;
          return;
        }

        try {
          let response;

          let data = {...this.selectedItem};
          data.dateOfService = (new Date(this.selectedItem.dateOfService)).getTime();

          if (this.selectedItem.id) {
            response = await dataService.updateServiceLogItem(data);
          } else {
            response = await dataService.createServiceLogItem(data);
          }
          if (response.data.code == 0) {
            this.loadData(this.year);
            hide();
          } else {
            this.errorMsg = 'Sorry! Something went wrong. Your submission could be incomplete.';
            console.log(response.data.message);
          }
        } catch (error) {
          this.errorMsg = 'Sorry! Something went wrong. Your submission could be incomplete.';
          console.log(error);
        }
      },

      resetModal() {
        this.errorMsg = "";
      }

    },
    filters: {
      moment: function (date) {
        return moment(date).format('MM/DD/YYYY');
      }
    }
  }
</script>