<template>
  <div>
    <hr />

    <div v-if="isBusy" class="text-center my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong> Loading...</strong>
    </div>

    <div v-if="isDataLoaded">
      <div>
        <h4 class="section-heading">
          <strong>Total number of hours by year:</strong>
        </h4>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Year</b-th>
                <b-th>Total hours</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totals.hoursByYear"
                v-bind:key="index"
              >
                <b-td>{{ item.year }}</b-td>
                <b-td>{{ item.total }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>
      </div>

      <hr />

      <div>
        <b-form class="mb-2" inline>
          <b-form-select
            :options="YEAR_OPTIONS"
            v-model="year"
            size="sm"
          ></b-form-select>
        </b-form>

        <h4 class="section-heading">
          <strong>Total number of hours for each focus area (by year):</strong>
        </h4>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Year</b-th>
                <b-th>Focus Area</b-th>
                <b-th>Total hours</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in hoursByFocusAreaByYear"
                v-bind:key="index"
              >
                <b-td>{{ item.year }}</b-td>
                <b-td>{{ item.volunteerEffort }}</b-td>
                <b-td>{{ item.total }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>
      </div>

      <hr />

      <div>
        <h4 class="section-heading">
          <strong>Total number of hours by organization (overall total for all years):</strong>
        </h4>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Service organization</b-th>
                <b-th>Number of hours</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totals.serviceOrg"
                v-bind:key="index"
              >
                <b-td>{{ item.organization }}</b-td>
                <b-td>{{ item.total }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Other Service organization</b-th>
                <b-th>Number of hours</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totals.serviceOrgOth"
                v-bind:key="index"
              >
                <b-td>{{ item.organizationOther }}</b-td>
                <b-td>{{ item.total }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>
      </div>

      <hr />

      <div>
        <h4 class="section-heading">
          <strong>Total number of hours by volunteer type:</strong>
        </h4>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Volunteer type</b-th>
                <b-th>Number of hours</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totals.byVolunteerServiceType"
                v-bind:key="index"
              >
                <b-td>{{ item.volunteerServiceType }}</b-td>
                <b-td>{{ item.total }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>
      </div>

      <hr />

      <div>
        <h4 class="mb-4 section-heading">
          <strong>Rating of the 5 questions under overall experience:</strong>
        </h4>

        <p>
          It's important to me that Goodyear is committed to making a positive
          impact in the community, with associate volunteer efforts.
        </p>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Answer</b-th>
                <b-th>Number of answers</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totals.gyPositiveImpact"
                v-bind:key="index"
              >
                <b-td>{{ item.formAnswer }}</b-td>
                <b-td>{{ item.totalAnswers }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>

        <hr />

        <p>
          I am more likely to speak positively of Goodyear as an employer because
          we are active in the community, with associate volunteer efforts.
        </p>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Answer</b-th>
                <b-th>Number of answers</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totals.gySpeakPositively"
                v-bind:key="index"
              >
                <b-td>{{ item.formAnswer }}</b-td>
                <b-td>{{ item.totalAnswers }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>

        <hr />

        <p>
          I am more likely to remain a Goodyear associate because we are active in
          the community, with associate volunteer efforts.
        </p>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Answer</b-th>
                <b-th>Number of answers</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totals.gyRemainAssociate"
                v-bind:key="index"
              >
                <b-td>{{ item.formAnswer }}</b-td>
                <b-td>{{ item.totalAnswers }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>

        <hr />

        <p>
          I feel a stronger sense of loyalty to Goodyear after participating in
          company supported volunteer opportunities.
        </p>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Answer</b-th>
                <b-th>Number of answers</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totals.gySenseOfLoyalty"
                v-bind:key="index"
              >
                <b-td>{{ item.formAnswer }}</b-td>
                <b-td>{{ item.totalAnswers }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>

        <hr />

        <p>
          Goodyear providing associate volunteer opportunities makes me feel more
          engaged at work.
        </p>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Answer</b-th>
                <b-th>Number of answers</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totals.gyWorkEngagement"
                v-bind:key="index"
              >
                <b-td>{{ item.formAnswer }}</b-td>
                <b-td>{{ item.totalAnswers }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>
      </div>

      <hr />

      <div>
        <h4 class="mb-4 section-heading">
          <strong>Week of Volunteering</strong>
        </h4>

        <p>
          The Week of Volunteering demonstrates Goodyear's commitment to serving
          our communities.
        </p>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Answer</b-th>
                <b-th>Number of answers</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totals.servingCommunities"
                v-bind:key="index"
              >
                <b-td>{{ item.formAnswer }}</b-td>
                <b-td>{{ item.totalAnswers }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>

        <hr />

        <p>
          The Week of Volunteering demonstrates Goodyear's emphasis on energizing
          the team.
        </p>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Answer</b-th>
                <b-th>Number of answers</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totals.energizingTeam"
                v-bind:key="index"
              >
                <b-td>{{ item.formAnswer }}</b-td>
                <b-td>{{ item.totalAnswers }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>

        <hr />

        <p>
          Participating in the Week of Volunteer improved my impressions of
          Goodyear.
        </p>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Answer</b-th>
                <b-th>Number of answers</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totals.improvedImpressionsGY"
                v-bind:key="index"
              >
                <b-td>{{ item.formAnswer }}</b-td>
                <b-td>{{ item.totalAnswers }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>

        <hr />

        <p>
          My Week of Volunteering activity made a difference in the community.
        </p>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Answer</b-th>
                <b-th>Number of answers</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totals.differenceCommunity"
                v-bind:key="index"
              >
                <b-td>{{ item.formAnswer }}</b-td>
                <b-td>{{ item.totalAnswers }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>

        <hr />

        <p>
          I understand how the volunteer activity I participated in relates to
          Goodyear's corporate social responsibility platform, Goodyear Better
          Future.
        </p>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Answer</b-th>
                <b-th>Number of answers</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totals.corporateSocialResp"
                v-bind:key="index"
              >
                <b-td>{{ item.formAnswer }}</b-td>
                <b-td>{{ item.totalAnswers }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>
      </div>

      <hr />

      <div>
        <h4 class="section-heading">
          <strong>
            "Overall, how would you rate your volunteer experience"
            totals:
          </strong>
        </h4>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Volunteer type</b-th>
                <b-th>Number of hours</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totals.experienceRate"
                v-bind:key="index"
              >
                <b-td>{{ item.overallExpirience }}</b-td>
                <b-td>{{ item.total }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>
      </div>

      <hr />

      <div>
        <h4 class="section-heading">
          <strong>Totals by Region:</strong>
        </h4>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Region</b-th>
                <b-th>Number of hours</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totals.byRegion"
                v-bind:key="index"
              >
                <b-td>{{ item.region }}</b-td>
                <b-td>{{ item.total }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>
      </div>

      <hr />

      <div>
        <h4 class="section-heading">
          <strong>Totals by Location:</strong>
        </h4>

        <b-row align-h="center">
          <b-col cols="6">
            <b-table-simple class="text-center" bordered small>
              <b-tr>
                <b-th>Location</b-th>
                <b-th>Number of hours</b-th>
              </b-tr>
              <b-tr
                v-for="(item, index) in totals.byLocation"
                v-bind:key="index"
              >
                <b-td>{{ item.location }}</b-td>
                <b-td>{{ item.total }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>
      </div>
    </div>

    <hr />
  </div>
</template>

<script>
const adminService = require("../../services/admin.service");

export default {
  data() {
    return {
      totals: {
        hoursByYear: [],
        hoursByFocusArea: [],
        serviceOrg: [],
        serviceOrgOth: [],
        byVolunteerServiceType: [],
        experienceRate: [],
        byRegion: [],
        byLocation: [],
        gyPositiveImpact: [],
        gySpeakPositively: [],
        gyRemainAssociate: [],
        gySenseOfLoyalty: [],
        gyWorkEngagement: [],
        servingCommunities: [],
        energizingTeam: [],
        improvedImpressionsGY: [],
        differenceCommunity: [],
        corporateSocialResp: []
      },

      year: new Date().getFullYear(),
      isDataLoaded: false,
      isBusy: false,

      errorMessage: "",
      showErrorModal: false,
    };
  },
  mounted: async function () {
    this.isBusy = true;
    let response = await adminService.getTotals();
    if (response.status === 200) {
      this.totals = response.data.data;
      this.isBusy = false;
      this.isDataLoaded = true;
    } else {
      this.errorMessage = response.data.message;
      this.showErrorModal = true;
    }
  },
  methods: {},
  computed: {
    hoursByFocusAreaByYear() {
      return this.totals.hoursByFocusArea.filter(
        (item) => item.year === this.year
      );
    },
  },
  created() {
    let currentYear = new Date().getFullYear();
    this.YEAR_OPTIONS = [
      currentYear - 5,
      currentYear - 4,
      currentYear - 3,
      currentYear - 2,
      currentYear - 1,
      currentYear,
    ];
  },
};
</script>

<style scoped>
.section-heading {
  color: rgb(39, 75, 167);
}
</style>
